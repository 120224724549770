import React from 'react'
import App from './App.js'
import './styles/tailwind.css';
import { Provider } from 'react-redux'; // Import Provider from react-redux
import  { store }  from './store/index.jsx'; // Import your Redux store
import { createRoot } from 'react-dom/client';


const container = document.getElementById('root');
const root = createRoot(container); // Create a root.

root.render(

  <React.StrictMode>
    <Provider store={store}> {/* Wrap your application with Provider and pass in the store */}
      <App />
    </Provider>
  </React.StrictMode>
);