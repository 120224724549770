// Location: src/components/NavigationBar.js
import React from 'react';
import { useScroll } from '../contexts/ScrollContext.js';
import bvmlogo from '../assets/bvmlogo.png';
import '../styles/tailwind.css';
import { Link, useLocation } from 'react-router-dom';


const NavigationBar = () => {
    const location = useLocation();
    const { isScrolled, scrollY } = useScroll();
    const isHomePage = location.pathname === '/';
    const [isOpen, setIsOpen] = React.useState(false);
    // Determine if the logo should be shown based on the scroll position and current page
    const showLogo = !isHomePage ? true : isScrolled && scrollY > 300;

    return (
        <nav className={`navbar fixed top-0 w-full bg-transparent bg-opacity-75 shadow-md text-white`} style={{ minHeight: '90px', zIndex: 9999 }}>            <div className="max-w-6xl mx-auto px-4 flex justify-between items-center">
            <Link to="/" className="p-2 flex items-center">
                <img src={bvmlogo} alt="Navbar Logo" style={{
                    width: 50,
                    transition: 'opacity 0.5s ease',
                    opacity: showLogo ? 1 : 0,
                }} />
            </Link>
            <div className="hidden md:flex items-center space-x-1">
                {/* Desktop Links */}
                <Link to="/services" className="nav-link text-base md:text-lg lg:text-xl px-4 md:px-6 lg:px-8">Services</Link>
                <Link to="/processes" className="nav-link text-base md:text-lg lg:text-xl px-4 md:px-6 lg:px-8">Processes</Link>
                <Link to="/about-us" className="nav-link text-base md:text-lg lg:text-xl px-4 md:px-6 lg:px-8">About Us</Link>
                <Link to="/contact" className="nav-link text-base md:text-lg lg:text-xl px-4 md:px-6 lg:px-8">Contact</Link>
            </div>
            <div className="md:hidden flex items-center">
                <button onClick={() => setIsOpen(!isOpen)} className="text-green-600">
                    {/* Hamburger icon */}
                    <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path></svg>
                </button>
            </div>
        </div>

            <div className={`${isOpen ? 'fixed right-4 top-[90px] py-1 w-1/4 rounded-xl text-center items-center cascade-animation' : 'hidden'} md:hidden`} style={{ background: 'transparent', boxShadow: 'none' }}>
                {/* Dropdown Links */}
                <Link to="/services" className="nav-link block w-full my-1" onClick={() => setIsOpen(false)}>Services</Link>
                <Link to="/processes" className="nav-link block w-full my-1" onClick={() => setIsOpen(false)}>Processes</Link>
                <Link to="/about-us" className="nav-link block w-full my-1" onClick={() => setIsOpen(false)}>About Us</Link>
                <Link to="/contact" className="nav-link block w-full my-1" onClick={() => setIsOpen(false)}>Contact</Link>
            </div>
        </nav>
    );
};

export default NavigationBar;
