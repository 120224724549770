import { createSlice } from "@reduxjs/toolkit";
const initialState = {

};
export const chatbotStatusSlice = createSlice({
  name: "chatbotStatusSlice",
  initialState,
  reducers: {
    
    },
    
},);

export default chatbotStatusSlice.reducer;
