import React from 'react';
import { useScroll } from '../contexts/ScrollContext.js';
import { useLocation } from 'react-router-dom'; // Import useLocation
import '../styles/tailwind.css';

const Header = () => {
    const { isScrolled } = useScroll();
    const location = useLocation(); // Use useLocation to get the current path

    // Conditional rendering based on the current path
    const renderHeaderContent = () => {
        switch (location.pathname) {
          case '/services':
            return <div>Services</div>; 
          case '/processes':
            return <div>Processes</div>;
          case '/about-us':
            return <div>About Us</div>;
          case '/contact':
            return <div>Contact</div>;
          case '/ecopractices':
             return <div>Eco Practices</div>;
          case '/':
            return null; // No specific content for root necessary
       
          default:
            return null; // Fallback content
        }
      };
      
// Use a variable to store the content to be rendered
const headerContent = renderHeaderContent();
    const headerVisibilityClass = isScrolled ? 'header-hidden' : '';
    return (
        // Only render the header element if there is content
        headerContent ? (
            <div className="header-container"> {/* New div wrapper with a class */}
            <header className={`header ${headerVisibilityClass} fixed top-90px z-20 transition-all ease-out duration-300`}>
                <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-green-900">
                    {headerContent}
                </h1>
            </header>
        </div>
        ) : null // Return null if headerContent is null, effectively hiding the header
    );
};

export default Header;