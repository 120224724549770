import React, { useEffect } from 'react';

const DynamicBackground = ({ children }) => {
  useEffect(() => {
    // Function to safely update background sizes
    const updateBackgroundSizes = () => {
      const topImage = document.querySelector('.top-image');
      const bottomImage = document.querySelector('.bottom-image');
      
      // Ensure elements exist before attempting to access properties
      if (topImage && bottomImage) {
        const topImageHeight = topImage.clientHeight;
        const bottomImageHeight = bottomImage.clientHeight;

        document.documentElement.style.setProperty('--wood-top-height', `${topImageHeight}px`);
        document.documentElement.style.setProperty('--wood-bottom-height', `${bottomImageHeight}px`);
      }
    };

    // Attach the event listener for resize events
    window.addEventListener('resize', updateBackgroundSizes);
    // Initial update
    updateBackgroundSizes();

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', updateBackgroundSizes);
  }, []);

  return (
    <div className="wood-container">
      {children}
    </div>
  );
};

export default DynamicBackground;
