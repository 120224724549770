import './styles/tailwind.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ScrollProvider } from './contexts/ScrollContext.js';
import NavigationBar from './components/NavigationBar.js';
import HomePage from './components/HomePage.js';
import ServicesPage from './components/ServicesPage.js';
import AboutPage from './components/AboutPage.js';
import ProcessesPage from './components/ProcessesPage.js';
import ContactPage from './components/ContactPage.js';
import EcoPracticesPage from './components/EcoPracticesPage.js';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import PrivacyPolicyPage from './components/PrivacyPolicy.js';

const App = () => {
  return (
    <ScrollProvider>
      <Router>
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "LocalBusiness",
                "name": "Boultbee Vegetation Management",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "P.O. Box 94 Station Main",
                  "addressLocality": "Penticton",
                  "addressRegion": "BC",
                  "postalCode": "V2A 6J9",
                  "addressCountry": "CA"
                },
                "telephone": "+1-250-492-0296",
                "description": "Expert weed control services across British Columbia, specializing in eco-friendly solutions and integrated pest management."
              }
            `}
          </script>
        </Helmet>
        <NavigationBar />
        <Header />
        <Routes>
          <Route path="/*" element={<HomePage />} />
          <Route path="/services/*" element={<ServicesPage />} />
          <Route path="/about-us/*" element={<AboutPage />} />
          <Route path="/processes/*" element={<ProcessesPage />} />
          <Route path="/contact/*" element={<ContactPage />} />
          <Route path="/ecopractices/*" element={<EcoPracticesPage />} />
          <Route path="/privacy-policy/*" element={<PrivacyPolicyPage />} />
        </Routes>
        <Footer /> {/* Ensure Footer is outside Routes but inside Router */}
      </Router>
    </ScrollProvider>
  );
};

export default App;