import React, { createContext, useState, useContext, useEffect } from 'react';

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
    const [scrollInfo, setScrollInfo] = useState({ isScrolled: false, scrollY: 0 });

    useEffect(() => {
        const handleScroll = () => {
            setScrollInfo({ isScrolled: window.scrollY > 30, scrollY: window.scrollY });
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <ScrollContext.Provider value={scrollInfo}>
            {children}
        </ScrollContext.Provider>
    );
};

// Custom hook to use the scroll context
export const useScroll = () => useContext(ScrollContext);
