import { Parallax } from 'react-parallax';
import HeroBanner from './HeroBanner.js';
import bvmlogo from '../assets/bvmlogo.png';
import ResidentialServicesBanner from './ResidentialServicesBanner.js';
import { useScroll } from '../contexts/ScrollContext.js';
const HeroSection = ({ backgroundImage, homepage }) => {
  const heroImage = {
    backgroundImage: `url(${backgroundImage})`
  };
  const { isScrolled } = useScroll();
  const bannerVisibilityClass = isScrolled ? 'header-hidden' : '';

  return (
    <Parallax strength={100}>
      <div className="hero relative flex flex-col justify-end bg-cover" style={heroImage}>
      <div className= {`${bannerVisibilityClass}`}><ResidentialServicesBanner /></div>
        {homepage && (
          <><div className="logo-container z-10">
            <img src={bvmlogo} alt="Company Logo" className="logo-animate mx-auto mt-0 w-32 h-auto md:w-48 lg:w-56" />
          </div><div className="absolute inset-0 bg-black bg-opacity-50"></div><HeroBanner /></>)}
      </div>
    </Parallax>
  );
};

export default HeroSection;