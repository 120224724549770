import React from 'react';
import '../styles/tailwind.css';
import '../styles/About.css';
import '../styles/ContactStyles.css';

const PrivacyPolicyPage = () => {
  return (
    <>
      <div className='bg-custom-earth'>
        <div className="wood-container pop-out-border">
          <div className="min-h-screen flex flex-col justify-center">
  
            {/* Privacy Policy Section */}
            <div className="text-container-blur flex flex-col items-center justify-center px-4 lg:px-20">
              <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center">Privacy Policy for Boultbee Vegetation Management</h2>
              
              <p className="text-md sm:text-lg lg:text-xl text-gray-700 text-center mt-10">Effective Date: March 8th, 2024</p>
              
              <p className="text-md sm:text-lg lg:text-xl text-gray-700 mt-5">
                Welcome to Boultbee Vegetation Management, accessible from our official site. Protecting your private information is our priority, and this Privacy Policy outlines our practices regarding the collection, use, and safeguarding of your personal data.
              </p>

                           {/* More on Information Collection and Use */}
                           <h3 className="text-xl sm:text-2xl lg:text-3xl font-bold text-center mt-10"> Information Collection And Use</h3>
                           <p className="text-md sm:text-lg lg:text-xl text-gray-700 mt-5">
                We collect personal information only when you voluntarily provide it through our website's forms or contact mechanisms. This information is essential for delivering the services you request and for effective communication.
              </p>

              {/* Types of Data Collected */}
              <h3 className="text-xl sm:text-2xl lg:text-3xl font-bold text-center mt-10">Types of Data Collected</h3>
              <p className="text-md sm:text-lg lg:text-xl text-gray-700 mt-5">
                Personal Data: While using our Service, we may ask for certain personally identifiable information, including but not limited to: Email address, First name and last name, Phone number, Address, State, Province, ZIP/Postal code, City, Cookies, and Usage Data.
              </p>

              {/* Use of Data */}
              <h3 className="text-xl sm:text-2xl lg:text-3xl font-bold text-center mt-10">Use of Data</h3>
              <p className="text-md sm:text-lg lg:text-xl text-gray-700 mt-5">
                Boultbee Vegetation Management uses the collected data for various purposes, including to provide and maintain our Service, to notify you about changes to our Service, and to provide customer support. Your information allows us to respond to your inquiries and fulfill your requests.
              </p>

              {/* Cookies and Tracking Data */}
              <h3 className="text-xl sm:text-2xl lg:text-3xl font-bold text-center mt-10">Cookies and Tracking Data</h3>
              <p className="text-md sm:text-lg lg:text-xl text-gray-700 mt-5">
                We use cookies to track activities on our Service and maintain certain information. Cookies are files with a small amount of data that may include an anonymous unique identifier.
              </p>

              {/* Data Security */}
              <h3 className="text-xl sm:text-2xl lg:text-3xl font-bold text-center mt-10">Data Security</h3>
              <p className="text-md sm:text-lg lg:text-xl text-gray-700 mt-5">
                The security of your data is important to us, but remember that no method of transmission over the internet or method of electronic storage is 100% secure. We strive to use commercially acceptable means to protect your Personal Data, but we cannot guarantee its absolute security.
              </p>

              {/* Changes to This Privacy Policy */}
              <h3 className="text-xl sm:text-2xl lg:text-3xl font-bold text-center mt-10">Changes to This Privacy Policy</h3>
              <p className="text-md sm:text-lg lg:text-xl text-gray-700 mt-5">
                We may update our Privacy Policy periodically. We will notify you of any changes by posting the new policy on our website.
              </p>

              {/* Contact Us */}
              <h3 className="text-xl sm:text-2xl lg:text-3xl font-bold text-center mt-10">Contact Us</h3>
              <p className="text-md sm:text-lg lg:text-xl text-gray-700 mt-5">
                For any questions about this Privacy Policy, please contact us.
              </p>

              
            </div>
  
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
