import React from 'react';
import { Link } from 'react-router-dom'; // Ensure you import Link from 'react-router-dom'

const Footer = () => {
    return (
        <footer className="bg-gray-300 text-gray-900 text-center p-4 mt-auto">
            <div className="container mx-auto">
                <div className="flex flex-row justify-center gap-4 mb-4 text-xs sm:text-sm">
                    {/* Adjusted text size and flex direction */}
                    <Link to="/about-us" className="hover:text-green-500 transition-colors">About Us</Link>
                    <Link to="/services" className="hover:text-green-500 transition-colors">Services</Link>
                    <Link to="/privacy-policy" className="hover:text-green-500 transition-colors">Privacy Policy</Link>
                    <Link to="/sitemap" className="hover:text-green-500 transition-colors">Sitemap</Link>
                    <Link to="/contact" className="hover:text-green-500 transition-colors">Contact</Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
