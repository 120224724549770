import { useRef, useCallback } from 'react';

const useHubSpotForm = ({ region, portalId, formId, targetId }) => {
  const scriptLoaded = useRef(false);

  const loadHubSpotForm = useCallback(() => {
    if (scriptLoaded.current) return;

    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region,
          portalId,
          formId,
          target: `#${targetId}`
        });
        scriptLoaded.current = true;
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [region, portalId, formId, targetId]);

  return { loadHubSpotForm };
};

export default useHubSpotForm;
