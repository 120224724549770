// src/redux/animationSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const animationSlice = createSlice({
  name: 'animation',
  initialState: {
    isAnimating: false,
  },
  reducers: {
    startAnimation: state => {
      state.isAnimating = true;
    },
    endAnimation: state => {
      state.isAnimating = false;
    },
  },
});

export const { startAnimation, endAnimation } = animationSlice.actions;

export const selectIsAnimating = state => state.animation.isAnimating;

export default animationSlice.reducer;
