import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/tailwind.css';


const Banner = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 30000); // 30 seconds

        // Clear the timer when the component is unmounted
        return () => clearTimeout(timer);
    }, []);

    if (!isVisible) {
        return null;
    }


    const handleNoClick = () => {
        setIsVisible(false);
    };

    const handleExpandClick = () => {
        setIsExpanded(true);
    };

    return (
        
        <div className={`residentialbanner fixed top-90px z-20 transition-all ease-out duratiion-300  flex flex-col delay-4000`}>
        <div className="flex justify-center items-center flex-col">
            {!isExpanded ? (
                <div className="flex flex-col md:flex-row justify-between items-center">
                    <p className="text-center text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold">
                        <span className="md:hidden text-sm flex-col ">Residential services</span>
                        <span className="hidden md:inline">for residential services</span>
                    </p>
                    <button className="ctaclick-button justify-between text-center bg-green-500 " onClick={handleExpandClick}>
                        Click here
                    </button>
                </div>
                ) : (

                    // ...
                    <div className="flex justify-between items-center space-x-4">
                        <div className="flex flex-col space-y-4">
                            <p className="text-center text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                                Boultbee Tree and Lawn serves the Okanagan Valley region. Are you within that area? <p className="text-right italic whitespace-nowrap text-xs md:text-sm lg:text-md xl:text-lg">
                                Clicking yes redirects to an external page
                            </p>
                            </p>
                           
                        </div>
                        <div className="flex flex-row">
                            <button className="ctaclick-button text-center bg-red-500-important text-sm" onClick={handleNoClick}>
                                No
                            </button>
                            <Link to="https://boultbeetreeandlawncom.wordpress.com/" className="ctaclick-button text-center text-lg md:text-xl lg:text-2xl xl:text-3xl">
                                Yes
                            </Link>
                        </div>
                    </div>
                    // ...

                )}
            </div>
        </div>
    );
};

export default Banner;