import React from 'react';
import gpsmap from '../assets/googlemapsimg.jpg';
import diagram from '../assets/smallDiag.png';
import flowmeter from '../assets/flowmeterwbackground.png';
import Section from './Section.js';

const Processes = () => {
  return (
    <div className='bg-custom-earth'>
      <div className="wood-container">
        <div className="min-h-screen flex flex-col">

          {/* Precision GPS Area Mapping Section */}
          <Section 
            heading="Precision GPS Area Mapping" 
            paragraph="GPS Technology maps boom-applied spray areas in real-time, ensuring thorough coverage by preventing overlaps and missed spots. We can later overlay these images onto Google Earth to view area coverage. We also create quotes in-house using google earth imagery."
            imgSrc={gpsmap}
            altText="Precision GPS Mapping"
          />

          {/* Efficient Injector Technology Section - Refactored to use Section Component */}
          <Section 
            heading="Efficient Injector Technology for Quick Product Switching"
            paragraph="With injector technology, switching between herbicides becomes seamless, reducing loading and handling time of chemical products. The application process promotes efficiency and safety while providing highly accurate product application rates."
            imgSrc={diagram}
            altText="Efficient Injector Technology"
          />

          {/* Adaptive Flow Control Section - Refactored to use Section Component */}
          <Section 
            heading="Adaptive Flow Control for Consistent Coverage"
            paragraph="Adaptive flow control adjusts the herbicide spray rate to the vehicle's speed, ensuring even application and minimizing waste."
            imgSrc={flowmeter}
            altText="Adaptive Flow Control"
          />

        </div>
      </div>
    </div>
  );
}

export default Processes;
