import { Link } from 'react-router-dom';
import '../styles/HeroBoxStyles.css'; // Importing the CSS file for styles
import React, { useState, useEffect } from 'react';



const texts = ["Commercial", "Weed Control ", "Experts"];

const HeroBanner = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [animationPhase, setAnimationPhase] = React.useState('fadeIn');
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    let timer;
    if (currentTextIndex < texts.length - 1) {
      if (animationPhase === 'fadeIn') {
        timer = setTimeout(() => {
          setAnimationPhase('fadeOut');
        }, 1100); // Wait for fade-in to complete
      } else {
        timer = setTimeout(() => {
          setCurrentTextIndex(currentTextIndex + 1);
          setAnimationPhase('fadeIn');
        }, 1100); // Change text after fade-out
      }
    } else if (animationPhase === 'fadeIn') {
      timer = setTimeout(() => {
        setAnimationPhase('fadeOut');
      }, 1100); // Wait for fade-in to complete
    }
    else if (animationPhase === 'fadeOut') {
      timer = setTimeout(() => {
        setShowButton(true);
      }, 800); // Show button after last text fades out
    }

    return () => clearTimeout(timer);
  }, [currentTextIndex, texts.length, animationPhase]);

  return (
    <div className="z-10 p-4 md:p-12 text-center flex justify-center items-center" style={{ position: 'relative' }}>
      {!showButton ? (
        <div className={`ctahero-text-item ${animationPhase}`} key={currentTextIndex}>
          <span>{texts[currentTextIndex]}</span>
        </div>
      ) : (
        <Link to="/contact" className="ctahero-button">
          Get a Quote
        </Link>
      )}
    </div>
  );
};

export default HeroBanner;
