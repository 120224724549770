import React, { useEffect } from 'react';
import useHubSpotForm from '../hooks/useHubSpotForm.js';
import '../styles/tailwind.css';
import '../styles/About.css';
import '../styles/ContactStyles.css';
import { Helmet } from 'react-helmet';

const ContactPage = () => {
  const { loadHubSpotForm } = useHubSpotForm({
    region: "na1",
    portalId: "24377151",
    formId: "c748ee53-2e4a-4865-8298-0a2ee75882a9",
    targetId: 'hubspotForm'
  });

  useEffect(() => {
    loadHubSpotForm(); // This function will handle loading the script and form
  }, [loadHubSpotForm]);



  return (
    <>
     <Helmet>
        <title>Contact Us | Boultbee Vegetation Management</title>
        <meta name="description" content="Reach out to us anytime via our Contact Page. Our team is ready to help you with your queries regarding pricing or any questions you might have." />
        <meta name="keywords" content="contact, customer support, help, pricing queries"/>
        <link rel="canonical" href="https://www.weedmanager.com.com/contact" />
      </Helmet>
      <div className='bg-custom-earth'>
        <div className="wood-container">
          <div className="min-h-screen flex flex-col">

            {/* Contact Information Section */}
            <div className="flex flex-col items-center justify-start contact-text-container-blur-top-banner">
              {/* Adjusted font sizes for h2 from text-xl to lg:text-2xl for larger screens and base size for small screens */}
              <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center">Looking for pricing? Have a Question?</h2>

              {/* Adjusted paragraph text size for better readability on small screens */}
              <p className="text-lg sm:text-xl lg:text-2xl text-gray-700 text-center mt-2">
                Our team is available to answer your questions and provide pricing. Get in touch with us below!
              </p>

              {/* Further adjusted text size for office hours for consistency across viewports */}
              <p className="text-md sm:text-lg lg:text-xl text-gray-700 text-center mt-10">
                Office Hours: 9 AM - 5 PM, Monday to Friday
              </p>
            </div>
            <div className="flex justify-center mt-4">
              <a href="tel:1-250-492-0296" className="bg-blue-500 text-white py-2 px-4 rounded">
                Call Now at 1-250-492-0296
              </a>
            </div>
            {/* HubSpot Form Section */}
            <section className="contact-text-container-blur flex flex-col items-center justify-center py-10" id="hubspotForm">

            </section>

          </div>
        </div>
      </div>
    </>
  );

};

export default ContactPage;
