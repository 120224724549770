// src/components/About.js
import React from 'react';
import '../styles/About.css';
import oldbvmtrucks from '../assets/oldboultbeetrucks.jpg';
import education from '../assets/education.jpg';
import innovationandtechnology from '../assets/innovationandtech.jpg';
import Section from './Section.js';
import DynamicBackground from './DynamicBackground.js';
import Helmet from 'react-helmet';
// import F550 from '../assets/bvmtruckaboutus.jpg';

const About = () => {
    return (
    <div className='bg-custom-earth'>
        <DynamicBackground>
        <Helmet>
            <title>Boultbee Vegetation Management | About Us</title>
            <meta name="description" content="Learn about Boultbee Vegetation Management's heritage, mission, and technological advances in weed control across British Columbia. Dedicated to safety, efficiency, and environmental stewardship." />
            <meta name="keywords" content="Weed Control, Vegetation Management, Herbicide Application, British Columbia, Sustainable Weed Control, Aquatic Weed Management"/>
            <link rel="canonical" href="https://www.weedmanager.com/about-us" />
            <meta property="og:title" content="Boultbee Vegetation Management | About Us" />
            <meta property="og:description" content="Explor Boultbee Vegetation Management's history and commitment to innovative weed control solutions in British Columbia. Learn about our technology and educational goals." />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.weedmanager.com/about-us" />

        </Helmet>

         {/* <HeroSection backgroundImage={F550}/> */}
     
            <div className="aboutushero relative bg-no-repeat bg-center md:bg-cover flex flex-col justify-end parallax mt-8">
            </div>

        <div className="min-h-screen flex flex-col">
       

          {/* Our Heritage and Mission Section */}
          <Section
            heading="Our Heritage and Mission"
            paragraph="Since our start in 1984, Boultbee Vegetation Management has led the way in offering exceptional weed control solutions across British Columbia. As a proud third-generation family business, our history is deeply rooted in the herbicide application industry with early beginnings in the agricultural industry. Our steadfast commitment is to deliver superior weed control services, emphasizing professionalism, efficiency, and safety."
            imgSrc={oldbvmtrucks}
            altText="old trucks"
          />
{/* 
          Innovation and Technology Section */}
          <Section
            heading="Innovation and Technology"
            paragraph="A core component of our operations is technology. We are dedicated to using the latest advances in technology and science to improve our services and reduce our environmental impact. Our team is equipped with advanced tools and techniques, ensuring we provide effective, sustainable weed control solutions. This tech-focused approach allows us to address complex challenges and offer specialized services, including 9+ month zero-growth vegetation control and targeted aquatic weed management. By combining technology with our extensive industry knowledge, we continue to lead the field and set new standards for weed control services."
            imgSrc={innovationandtechnology}
            altText="innovation and technology"
          />

          {/* Education and Knowledge Section */}
          <Section
            heading="Education and Knowledge"
            paragraph="We serve railroads, pulp mills, oil refineries, municipalities, and governmental organizations with a broad set of needs. Education and knowledge is what sets our organization apart. We educate our applicators through mainly online courses, seminars, and other forms of media. A key contributor to our knowledge base is educational content provided by the Invasive Species Council of BC."
            imgSrc={education}
            altText="our commitment to education"
          />


        </div>
        </DynamicBackground>
    </div>
  );
}

export default About;
