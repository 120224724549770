

export const getMessage = async (inputMessage, setInputMessage) => {
    const options = {
        method: "POST",
        body: JSON.stringify({
            prompt: inputMessage, // Changed to match your Lambda function expectation
        }),
        headers: {
            "Content-Type": "application/json"
        }
    };

    try {
        const temp_input = inputMessage;
        setInputMessage(""); // Reset input message

        const response = await fetch(process.env.API_GATEWAY_URL, options); //API Gateway endpoint

        if (!response.ok) {
            setInputMessage(temp_input); // Restore input message if request fails
            throw new Error('Network response was not ok: ' + response.statusText);
        }

        const data = await response.json();
        console.log(data); // Log the response data

    } catch (error) {
        console.error('Fetch error:', error);
    }
};

let weedCounter = 0;
export const generateWeedBase = ({ weeds, backgroundImageDimensions, positionCustomization, threshold }) => {
    // Select a random weed image from the predefined array
    const randomWeed = weeds[Math.floor(Math.random() * weeds.length)];

    // Customizable position calculation based on passed function
    const { bottom, left } = positionCustomization(backgroundImageDimensions);

    // Define base and minimum sizes for the weed images
    const baseSize = 125; // Base size for a weed at the bottom (0%)
    const minSize = 25;  // Minimum size for a weed at the top (80%)


    let size; // Variable to hold the final size of the weed

    // Adjust the size based on the weed's position to simulate perspective
    if (bottom <= threshold) {
        // Gently reduce the size for weeds closer to the bottom
        const gentleSlopeSize = baseSize - ((baseSize - minSize) / 2) * (bottom / threshold);
        size = gentleSlopeSize;
    } else {
        // More aggressively reduce the size for weeds closer to the top
        const aggressiveSlopeRange = 80 - threshold;
        const aggressiveSlopeFactor = (bottom - threshold) / aggressiveSlopeRange;
        const aggressiveSlopeSize = minSize + ((baseSize - minSize) / 2) * (1 - aggressiveSlopeFactor);
        size = aggressiveSlopeSize;
    }

    // Generate random skew values
    const skewDegree = Math.random() * 10 + 20; // Random degree between 20 and 30
    const skewDirection = Math.random() > 0.5 ? '' : '-'; // Correctly apply the negative sign to the degree

    // Construct and return the weed object with dynamic styling
    return {
        id: `${bottom}-${left}-${randomWeed}-${weedCounter++}`, // Unique ID based on position and image        
        weed: randomWeed,
        style: {
            backgroundImage: `url(${randomWeed})`,
            bottom: `${bottom}%`, // Dynamic bottom position
            left: `${left}%`, // Dynamic left position
            width: `${size}px`, // Dynamic width based on position
            height: `${size}px`, // Dynamic height based on position
            // Apply dynamic transform for skew and maintain scale
            '--skewDeg': `${skewDirection}${skewDegree}deg` // Assigning custom property
        }
    };
};



// Custom position calculation for weeds within 70% of the page horizontally
export const positionWithin80up85Across = (backgroundImageDimensions) => {
    const bottom = Math.floor(Math.random() * 81)//a random position up to 70% the way up the page.
    const left = Math.floor(Math.random() * 86)
    // console.log("did we get here")
    return { bottom, left };
};
// Custom position calculation for weeds within 65% of the page horizontally
export const positionWithin65up85Across = (backgroundImageDimensions) => {
    const bottom = Math.floor(Math.random() * 66)//a random position up to 70% the way up the page.
    const left = Math.floor(Math.random() * 86)
    return { bottom, left };
};




// Custom position calculation for manually defined positions
export const manualPositionCustomization = () => {
    // Encapsulated list of pre-calculated coordinates for placing weeds on the path.
    let pathCoordinates = [
        { 'left': 21.1, 'bottom': 6.14 },
        { 'left': 39.7, 'bottom': 68.86 },
        { 'left': 63.9, 'bottom': 55.68 },
        { 'left': 35.7, 'bottom': 36.82 },
        { 'left': 43.05, 'bottom': 80.73 },
        { 'left': 40.75, 'bottom': 18.86 },
        { 'left': 52.2, 'bottom': 8.18 },
        { 'left': 54.75, 'bottom': 72.95 },
        { 'left': 75.1, 'bottom': 69.77 },
        { 'left': 60.45, 'bottom': 20.0 },
        { 'left': 61.85, 'bottom': 59.55 },
        { 'left': 66.35, 'bottom': 25.0 },
        { 'left': 70.8, 'bottom': 54.86 },
        { 'left': 73.05, 'bottom': 24.32 },
        { 'left': 73.2, 'bottom': 12.95 },
        { 'left': 45.35, 'bottom': 28.64 },
        { 'left': 76.7, 'bottom': 63.64 },
        { 'left': 73.25, 'bottom': 27.5 }];

    const getPathCoordinateAtRandom = () => {
        if (pathCoordinates.length === 0) {
            return null; // Return null if the array is empty
        }
        const randomIndex = Math.floor(Math.random() * pathCoordinates.length);
        const selectedCoordinates = pathCoordinates[randomIndex];
        // Remove the selected coordinate from the array
        pathCoordinates = pathCoordinates.filter((_, index) => index !== randomIndex);
        return selectedCoordinates;
    };

    // Function to get a random coordinate set from the list
    const coordinates = getPathCoordinateAtRandom();
    if (coordinates === null) {
        return null; // Return null if no coordinates are available
    }

    const { bottom, left } = coordinates;
    return { bottom, left };
};


