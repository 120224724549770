// Location: src/components/CircularNavigation.js
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook from React Router
import { Link } from 'react-router-dom'; // Import Link component from React Router

import circularbutton from '../assets/circularButton.png';

const CircularNavigation = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Function to handle click on a button, replaced with navigation logic using useNavigate
  const handleNavigation = (path) => {
    navigate(path);
  };
  const CircularButton = ({ href, alt, onClick, title, description }) => (
    <div className="aspect-w-1 aspect-h-1 flex flex-col items-center justify-start">
      <a href={href} onClick={onClick} className="circular-button">
        <img src={circularbutton} alt={alt} className="circular-image w-16 h-16 sm:w-18 sm:h-18" />
      </a>
      <Link to={href} onClick={onClick} className="text-sm sm:text-md md:text-xl font-bold mt-4 text-center text-shadow zoom-effect">
        {title}
      </Link>
      <Link to={href} onClick={onClick} className="text-base text-gray-600 text-center mt-2 hidden text-container-blur md:block zoom-effect">
        {description}
      </Link>
    </div>
  );
  return (
    <div className='metallic-border'>
      <div className="flex justify-center py-12 bg-earth-tone">
        <div className="grid grid-cols-3 gap-8 w-full max-w-6xl px-4">


          <CircularButton
            href="/services"
            alt="Services"
            onClick={(e) => { e.preventDefault(); handleNavigation('/services'); }}
            title="SERVICES"
            description="Find out our services and get transparent pricing information to help you budget for your vegetation management projects."
          />
          <CircularButton
            href="/ecopractices"
            alt="Eco Practices"
            onClick={(e) => { e.preventDefault(); handleNavigation('/ecopractices'); }}
            title="ECO PRACTICES"
            description="We use low impact products where available and follow Integrated Pest Management principles"
          />
          <CircularButton
            href="/processes"
            alt="Processes"
            onClick={(e) => { e.preventDefault(); handleNavigation('/processes'); }}
            title="PROCESSES"
            description="Discover our unique approach to vegetation management that sets us apart from the competition."
          />

          
        </div>
      </div>
    </div>
  );
};

export default CircularNavigation;
