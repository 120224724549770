// src/components/About.js
import React from 'react';
import '../styles/tailwind.css';
import '../styles/About.css'; // For specific non-Tailwind CSS rules or overrides
import plantstagemagnify from '../assets/plantstagemagnify.jpg';
import bclandscape from '../assets/bclandscape.jpg';
import tailoredherbicidetreatment from '../assets/tailoredtreatment.jpg';
import Section from './Section.js'; // Importing the Section component

const EcoPracticesPage = () => {
    return (
        <div className='bg-custom-earth'>
            <div className="wood-container">
                <div className="min-h-screen flex flex-col"> {/* Ensure full-screen height and vertical stacking */}

                    {/* Eco-Friendly Approach Section - Refactored to use Section component */}
                    <Section 
                        heading="Eco-Friendly Approach"
                        paragraph="We take our commitment to the environment seriously. Our eco-friendly stance is more than a policy, it's woven into everyday practices. We understand the delicate balance of our local ecosystems and strive to protect it, ensuring that our beautiful landscapes remain vibrant and healthy for generations to come. Our approach is grounded in the principles of Integrated Pest Management (IPM), a strategic, science-based process that minimizes environmental impact while effectively managing weed growth."
                        imgSrc={bclandscape}
                        altText="BC Landscape"
                    />

                    {/* Integrated Pest Management Section - Refactored to use Section component */}
                    <Section 
                        heading="Integrated Pest Management: Our Core Strategy"
                        paragraph="Our Integrated Pest Management (IPM) strategy lies at the heart of our eco-friendly approach. This method emphasizes the importance of making informed decisions in weed control, focusing on long-term prevention and minimal harm to non-target organisms. We consider the plant's lifecycle stage, environmental conditions, and susceptible modes of action when forming our treatment plans. We rotate our herbicides to avoid increasing herbicide resistance, and are always researching lower impact products that come on the market. This precise, thoughtful approach ensures that we use the right method at the right time, significantly reducing chemical use."
                        imgSrc={plantstagemagnify}
                        altText="plant stage with magnifying glass"
                    />


                    {/* Tailored Treatments and Low-Impact Herbicides Section - Refactored to use Section component */}
                    <Section 
                        heading="Tailored Treatments and Low-Impact Herbicides"
                        paragraph="We pride ourselves on our ability to tailor treatments to the specific weed being addressed, utilizing low-impact herbicides that are carefully selected for their reduced environmental footprint. Our choice of herbicides and application rates are calculated to achieve effective weed control while also considering the environment. This deliberate, cautious approach allows us to minimize the environmental impact."
                        imgSrc={tailoredherbicidetreatment}
                        altText="tailored herbicide treatment - image of a weed being sprayed"
                    />

                </div>
            </div>
        </div>
    );
};

export default EcoPracticesPage;
