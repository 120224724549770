import React from 'react';
import { useSelector } from 'react-redux'; // Import useSelector hook from react-redux
import { selectPlans } from '../store/slices/pricingPlanSlice.js'; // Import the selector to access plans from the Redux store
import PricingCard from './PricingCard.js';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO optimization
import dandelion from '../assets/dandelion.png';
import plantain from '../assets/plantain1.png';
import crabgrass from '../assets/crabgrass.png';
import clover from '../assets/clover.png';
import creepingbuttercup from '../assets/creepingbuttercup.png';
import cloverbrick from '../assets/cloverbrick.png';
import cloverbrick2 from '../assets/cloverbrick2.png';
import quackgrass from '../assets/quackgrass.png';
import babysbreath1 from '../assets/babysbreath1.png'
import babysbreath2 from '../assets/babysbreath2.png'
import babysbreath3 from '../assets/babysbreath3.png'
import burchervil1 from '../assets/burchervil1.png'
import burchervil2 from '../assets/burchervil2.png'
import commonreed1 from '../assets/commonreed1.png'
import commonreed2 from '../assets/commonreed2.png'
import grassbackground from '../assets/grassbackground.jpg';
import pathbackground from '../assets/backgroundpath.jpg';
import grasslandbackground from '../assets/grasslands.jpg';
import highrail from '../assets/highrail.jpg';
import Section from './Section.js';
import { generateWeedBase, positionWithin65up85Across, positionWithin80up85Across, manualPositionCustomization } from '../utils.js';


// Array of background images for cards
const cardBackgrounds = [
    grassbackground,
    pathbackground,
    grasslandbackground,
];

const turfWeeds = [dandelion, plantain, crabgrass, clover, creepingbuttercup]
const baregroundWeeds = [cloverbrick, cloverbrick2, quackgrass];
const noxiousweeds = [babysbreath1, babysbreath2, babysbreath3, burchervil1, burchervil2, commonreed1, commonreed2]

const backgroundImageDimensions = { width: 2000, height: 440 };


//  Use of the base function used with the 80% height range customization for random weeds to be generated in
const generateFirstCardWeeds = () => generateWeedBase({
    weeds: turfWeeds,
    backgroundImageDimensions,
    positionCustomization: positionWithin80up85Across,
    threshold: 60
});

//  Use of the base function used with the manual placement of random weeds generated. 
const generateMiddleCardWeeds = () => generateWeedBase({
    weeds: baregroundWeeds,
    backgroundImageDimensions,
    positionCustomization: manualPositionCustomization,
    threshold: 60
});

//  Use of the base function used with the 65% height range customization for random weeds to be generated in 
const generateEndCardWeeds = () => generateWeedBase({
    weeds: noxiousweeds,
    backgroundImageDimensions,
    positionCustomization: positionWithin65up85Across,
    threshold: 10
});


// The ServicesPage Component is responsible for rendering the pricing section of the website.

const ServicesPage = () => {
    const plans = useSelector(selectPlans);
    const handlePlan = (plan, index) => {
        let customWeedGenerator = null;
        if (index === 0) {
            customWeedGenerator = generateFirstCardWeeds;
        } else if (index == 1) {
            customWeedGenerator = generateMiddleCardWeeds;
        } else if (index == 2) {
            customWeedGenerator = generateEndCardWeeds;
        }

        const backgroundStyle = {
            backgroundImage: `url(${cardBackgrounds[index % cardBackgrounds.length]})`,
        };

        return (
            <div className="weed-container border border-gray-300 shadow-lg rounded-4xl text-center my-1" key={plan.id} style={backgroundStyle}>
                <PricingCard {...plan} generateCustomWeed={customWeedGenerator} />
            </div>
        );
    };

    return (
        <div className='bg-custom-earth'>
            <Helmet>
                <title>Services | Boultbee Vegetation Management</title>
                <meta name="description" content="Competitive pricing for turf, bare ground, and noxious weed management services." />
            </Helmet>
            <div className='wood-container'>
                <div className="justify-center mt-6 mb-4">
                    <div className="flex flex-col mx-2 md:mx-4 lg:mx-6 xl:mx-8">
                        {plans.map((plan, index) => handlePlan(plan, index))}
                    </div>
                </div>
                <Section 
    heading="Specialty Services"
    paragraph={
        <React.Fragment>
            <span style={{fontSize: '1xl', fontWeight: 'bold', color: 'black', marginBottom: '1', marginTop: '2'}}>We offer a variety of specialty services, which include but are not limited to:</span>
            <br />
            <span style={{fontSize: '1xl', fontWeight: 'bold', color: 'black', marginBottom: '1', marginTop: '2'}}>- Aquatic Weed Control: Safeguard your water sources from invasive aquatic plants, algae, or other unwanted vegetation.</span>
            <br />
            <span style={{fontSize: '1xl', fontWeight: 'bold', color: 'black', marginBottom: '1', marginTop: '2'}}>- Sidewalks and Brickwork: Ensure that weeds in cracks, crevices, and pavement joints are effectively eradicated, preventing regrowth and structural damage.</span>
            <br />
            <span style={{fontSize: '1xl', fontWeight: 'bold', color: 'black', marginBottom: '1', marginTop: '2'}}>- High-Rail Weed Control: Keep your railways clear of vegetation with our high-rail weed control services. Our rail mounting equipment ensures that your rail line remain free of weeds with an even buffer zone on each side.</span>
            <br />
            <span style={{fontSize: '1xl', fontWeight: 'bold', color: 'black', marginBottom: '1', marginTop: '2'}}>- Cablestrips and Medians: Increase longevity and maintain aesthetics of your cablestrips and medians with a low-impact, effective treatment plan.</span>
            <br />
            <span style={{fontSize: '1xl', fontWeight: 'bold', color: 'black', marginBottom: '1', marginTop: '2'}}>- Sportsfield Maintenance: Protect the playability and safety of your sports fields with our specialized infield maintenance services.</span>
        </React.Fragment>
    }
    imgSrc={highrail}
    altText="High-Rail Weed Control Image"
/>
            </div>
        </div>
    );
};


export default ServicesPage;
