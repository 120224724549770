//src/components/HomePage.js
import CircularNavigation from './CircularNavigation.js';
import { Helmet } from 'react-helmet';
import ivmalogo from '../assets/ivmaofbc124x59_2x.png';
import avettalogo from '../assets/Avetta-Logo.png';
import isclogo from '../assets/ISClogo.png';
import HeroSection from './HeroSection.js';
import bvmheroimage from '../assets/bvmhomepagehero.jpg';
import Section from './Section.js';
const HomePage = () => {
  return (
    // Flex container to push footer to the bottom
    <div className='bg-custom-earth'>
      <div className="wood-container">
        <div className="flex flex-col min-h-screen bg-green-50 text-gray-900 mt-[-50px]">
          <Helmet>
            <title>Expert Weed Control Services in British Columbia | Boultbee Vegetation Management</title>
            <meta name="description" content="Boultbee Vegetation Management offers professional commercial and industrial weed control services across British Columbia. Certified and eco-friendly solutions." />
          </Helmet>



          {/* Hero Section with adjusted height and overlay for visual effect */}
          <HeroSection backgroundImage={bvmheroimage} homepage />


          {/* 3 round main website sections */}
          <CircularNavigation />


          <div className="banner-flex-container">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-gray-800 text-center w-full">Professional Affiliations</h2>
            <div className="flex justify-center items-center space-x-4 mt-4">
              <a href="https://www.ivma.com/" target="_blank" rel="noopener noreferrer">
                <img src={ivmalogo} alt="Integrated Vegetation Management Association of BC logo" className="affil-logo-resize" />
              </a>
              <a href="https://www.avetta.com/" target="_blank" rel="noopener noreferrer">
                <img src={avettalogo} alt="Avetta Certified Partner logo" className="affil-logo-resize" />
              </a>
              <a href="https://bcinvasives.ca/" target="_blank" rel="noopener noreferrer">
                <img src={isclogo} alt="Invasive Species Council of British Columbia logo" className="affil-logo-resize" />
              </a>


            </div>
            <p className="mt-4 text-lg sm:text-xl md:text-2xl text-gray-600 text-center w-full">Proud members of leading industry organizations, committed to excellence and integrity in vegetation management.</p>
          </div>

          {/* <Section
            heading="Comprehensive Weed Management Solutions in British Columbia"
            paragraph="Boultbee Vegetation Management is trusted in maintaining commercial properties across British Columbia. With a general route covering multiple key commercial hubs, we ensure that our clients receive timely and effective weed control. Out-of-route services are available for a fee (based on the extra travel required)."
            altText="Expert weed control across British Columbia by Boultbee Vegetation Management"
          /> */}

        </div>
      </div>
    </div>

  );

};

export default HomePage;