import { configureStore } from "@reduxjs/toolkit";
import conversationsSliceReducer from "./slices/conversationsSlice";
import chatbotStatusSliceReducer from "./slices/chatbotStatusSlice";
import plansSliceReducer from './slices/pricingPlanSlice';
import animationReducer from './slices/animationSlice';


export const store = configureStore({
  reducer: {
    conversations: conversationsSliceReducer,
    chatbotStatus: chatbotStatusSliceReducer,
    plans: plansSliceReducer,
    animation: animationReducer,
  },
});

