// src/components/Section.js
import React from 'react';

const Section = ({ heading, paragraph, imgSrc, altText }) => {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-center text-container-blur">
            <div>
                <h2 className="text-xl md:text-2xl font-bold mt-2 text-left">{heading}</h2>
                <p className="text-base md:text-lg text-gray-700 text-left mt-2 lg:mt-8">{paragraph}</p>
            </div>
            {imgSrc && (
                <div className="order-first lg:order-none flex justify-center lg:justify-end p-2 md:p-3 lg:p-4 my-5 lg:my-0">
                    <figure>
                        <div className="flatSurface">
                            <div className="innerBevel">
                                <img src={imgSrc} alt={altText} className="map" />
                            </div>
                        </div>
                    </figure>
                </div>
            )}
        </div>
    );
};

export default Section;