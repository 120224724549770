import '../styles/PricingCardStyles.css';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash'; // Importing lodash for utility functions, such as debounce

// The PricingCard component with props for title, description, and price
const PricingCard = ({ title, description, price, generateCustomWeed, cardStyle }) => { // Added cardStyle to the props
  const dispatch = useDispatch(); // Hook to dispatch actions to the Redux store
  // Add local animation state
  const [isAnimatingLocally, setIsAnimatingLocally] = useState(false);

  const [currentWeeds, setCurrentWeeds] = useState([]); // State to track the current weeds displayed

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentWeeds.length < 8 && !isAnimatingLocally) {
        const weedGenerator = generateCustomWeed || generateRandomWeed; // Use custom generator if available
        setCurrentWeeds(prevWeeds => [
          ...prevWeeds,
          weedGenerator()
        ]);
      }
    }, 2000); // Interval set to 4 seconds for typo correction
  // Cleanup function to clear the interval on component unmount
    return () => clearInterval(interval);
}, [currentWeeds.length, isAnimatingLocally, generateCustomWeed]); // Add generateCustomWeed as a dependency


  // Callback to initiate initiate weed shriveling animation locally
  const initiateWeedShrivel = useCallback(() => {
    if (!isAnimatingLocally && currentWeeds.length > 0) {
      setIsAnimatingLocally(true); // Use local state to start animation
    }
  }, [isAnimatingLocally, currentWeeds.length]);

  // Debounced version of initiateWeedShrivel to prevent rapid re-triggering
  const debouncedInitiateWeedShrivel = useCallback(_.debounce(initiateWeedShrivel, 300), [initiateWeedShrivel]);

  // Callback handle the end of an animation locally
  const handleAnimationEnd = useCallback(() => {
    if (isAnimatingLocally) {
      setIsAnimatingLocally(false); // Use local state to end animation
      setCurrentWeeds([]); // Clear the weeds from state
    }
  }, [isAnimatingLocally]);
  // JSX for the PricingCard component, including dynamic classes and event handlers
  return (
    <div
      className={`weed-container border border-gray-300 shadow-lg rounded-4xl text-center ${isAnimatingLocally ? 'animate' : ''}`}
      onMouseEnter={debouncedInitiateWeedShrivel}
      style={cardStyle} // Apply the dynamic style passed as a prop
    >
      {currentWeeds.map((weed) => ( // Mapping over currentWeeds to render each weed
        <div
          key={weed.id} // Unique key for React list
          className={`weed`}
          style={weed.style} // Applying dynamic styles
          onAnimationEnd={handleAnimationEnd} // Handling animation end
        />
      ))}
      <div className=" mb-8 mt-4"> 
        <div className="mb-8"> 
          <h2 className="text-3xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-serif font-bold text-gray-900 sm:mb-4 md:mb-6 lg:mb-6 xl:mb-8 text-visible">{title}</h2> 
          <p className="text-1xl sm:text-1xl md:text-2xl lg:text-3xl xl:text-4xl font-bold text-gray-700 sm:mb-1 md:mb-2 lg:mb-3 xl:mb-4 card-text-container-blur text-visible">{description}</p> 
          <span className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl  font-bold text-gray-900 sm:mb-2 md:mb-3 lg:mb-4 xl:mb-5 price-container-blur text-visible">{price}</span> 
        </div>
        <Link to="/contact" className="ctapricecard-button">Get A Quote</Link>
      </div>
    </div>
  );
};

export default PricingCard;